// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-api-tsx": () => import("./../../../src/pages/api.tsx" /* webpackChunkName: "component---src-pages-api-tsx" */),
  "component---src-pages-beneficios-index-tsx": () => import("./../../../src/pages/beneficios/index.tsx" /* webpackChunkName: "component---src-pages-beneficios-index-tsx" */),
  "component---src-pages-beneficios-programa-referidos-tsx": () => import("./../../../src/pages/beneficios/programa-referidos.tsx" /* webpackChunkName: "component---src-pages-beneficios-programa-referidos-tsx" */),
  "component---src-pages-compliance-tsx": () => import("./../../../src/pages/compliance.tsx" /* webpackChunkName: "component---src-pages-compliance-tsx" */),
  "component---src-pages-converter-tsx": () => import("./../../../src/pages/converter.tsx" /* webpackChunkName: "component---src-pages-converter-tsx" */),
  "component---src-pages-emailvalidated-tsx": () => import("./../../../src/pages/emailvalidated.tsx" /* webpackChunkName: "component---src-pages-emailvalidated-tsx" */),
  "component---src-pages-empresas-tsx": () => import("./../../../src/pages/empresas.tsx" /* webpackChunkName: "component---src-pages-empresas-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-landing-pointsbolivia-tsx": () => import("./../../../src/pages/landing/pointsbolivia.tsx" /* webpackChunkName: "component---src-pages-landing-pointsbolivia-tsx" */),
  "component---src-pages-landing-pointsecuador-tsx": () => import("./../../../src/pages/landing/pointsecuador.tsx" /* webpackChunkName: "component---src-pages-landing-pointsecuador-tsx" */),
  "component---src-pages-landing-pointsperu-tsx": () => import("./../../../src/pages/landing/pointsperu.tsx" /* webpackChunkName: "component---src-pages-landing-pointsperu-tsx" */),
  "component---src-pages-login-forgot-tsx": () => import("./../../../src/pages/login/forgot.tsx" /* webpackChunkName: "component---src-pages-login-forgot-tsx" */),
  "component---src-pages-login-index-tsx": () => import("./../../../src/pages/login/index.tsx" /* webpackChunkName: "component---src-pages-login-index-tsx" */),
  "component---src-pages-login-newpassword-tsx": () => import("./../../../src/pages/login/newpassword.tsx" /* webpackChunkName: "component---src-pages-login-newpassword-tsx" */),
  "component---src-pages-nosotros-tsx": () => import("./../../../src/pages/nosotros.tsx" /* webpackChunkName: "component---src-pages-nosotros-tsx" */),
  "component---src-pages-privacypolicy-tsx": () => import("./../../../src/pages/privacypolicy.tsx" /* webpackChunkName: "component---src-pages-privacypolicy-tsx" */),
  "component---src-pages-profilecomplete-tsx": () => import("./../../../src/pages/profilecomplete.tsx" /* webpackChunkName: "component---src-pages-profilecomplete-tsx" */),
  "component---src-pages-quote-index-tsx": () => import("./../../../src/pages/quote/index.tsx" /* webpackChunkName: "component---src-pages-quote-index-tsx" */),
  "component---src-pages-register-tsx": () => import("./../../../src/pages/register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */),
  "component---src-pages-signup-confirmation-tsx": () => import("./../../../src/pages/signup/confirmation.tsx" /* webpackChunkName: "component---src-pages-signup-confirmation-tsx" */),
  "component---src-pages-signup-form-contact-information-tsx": () => import("./../../../src/pages/signup-form/contact-information.tsx" /* webpackChunkName: "component---src-pages-signup-form-contact-information-tsx" */),
  "component---src-pages-signup-form-document-validation-tsx": () => import("./../../../src/pages/signup-form/document-validation.tsx" /* webpackChunkName: "component---src-pages-signup-form-document-validation-tsx" */),
  "component---src-pages-signup-form-personal-information-fallback-tsx": () => import("./../../../src/pages/signup-form/personal-information-fallback.tsx" /* webpackChunkName: "component---src-pages-signup-form-personal-information-fallback-tsx" */),
  "component---src-pages-signup-form-personal-information-tsx": () => import("./../../../src/pages/signup-form/personal-information.tsx" /* webpackChunkName: "component---src-pages-signup-form-personal-information-tsx" */),
  "component---src-pages-signup-form-terms-tsx": () => import("./../../../src/pages/signup-form/terms.tsx" /* webpackChunkName: "component---src-pages-signup-form-terms-tsx" */),
  "component---src-pages-signup-form-tsx": () => import("./../../../src/pages/signup-form.tsx" /* webpackChunkName: "component---src-pages-signup-form-tsx" */),
  "component---src-pages-signup-form-upload-document-back-tsx": () => import("./../../../src/pages/signup-form/upload-document-back.tsx" /* webpackChunkName: "component---src-pages-signup-form-upload-document-back-tsx" */),
  "component---src-pages-signup-form-upload-document-front-tsx": () => import("./../../../src/pages/signup-form/upload-document-front.tsx" /* webpackChunkName: "component---src-pages-signup-form-upload-document-front-tsx" */),
  "component---src-pages-signup-resend-tsx": () => import("./../../../src/pages/signup/resend.tsx" /* webpackChunkName: "component---src-pages-signup-resend-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */)
}

